html {
    scroll-behavior: smooth
}

* {
    font-family: "Boing", "Roboto", "Helvetica", "Arial", "sans-serif"
}

body {
    overflow-x: hidden;
    background-color: white !important;
}

*:not(input) {
    line-height: 1.2 !important;
}

*::selection {
    background: #FFD900;
    color: #3F4142 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}